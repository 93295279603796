<template>
    <div class="">
        <div class="">
            <custom-alert v-if="displayAlert==true" :message="alertMessage"
                          v-on:showedAlert="resetAlert"></custom-alert>
            <div class="row">
                <div class="col-md-12 mt-3 mb-4">
                    <div class="card club-info">
                        <div class="card-header register-title">
                            <div class="row" v-if="current_members_default">
                              <div class="col-md-12">
                                <div class="alert alert-info alert-dismissible" role="alert">
                                  <strong>Current Members</strong> are auto filtered. Press "Reset" button if you want to see all players
                                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <h3 class="" v-if="subdomain != 'alliancefastpitch'">{{ titleLabel }}</h3>
                                    <h3 class="" v-else>Team Member Roster</h3>

                                <div class=" brd-black">
                                    <div class=" mt-2">
                                        <ol class="breadcrumb  p-0">
                                        <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                        <li class="breadcrumb-item"><Breadcrumbs/></li>
                                        </ol>
                                    </div>
                                </div></div>
                                <div class="col-md-6 text-right" v-if="getSiteProps('general.club_add_member_btn')">
                                <div class="col-md-12 m-roaster-btns" style="margin-top: 10px">
                                    <b-button variant="outline-secondary ml-2" v-b-modal.member-lookup-modal class="pull-right" v-if="subdomain!='alliancefastpitch'"><i class="fa fa-plus"></i>
                                    Add Member
                                </b-button>
                                <b-button variant="outline-primary mr-1" :disabled="exportPDFProgress" @click="exportConfirm()">
                                    <i class="fa" :class="exportPDFProgress ? 'fa-circle-notch fa-spin' : 'fa-file-export'"></i> Export
                                </b-button>
                                <b-button variant="outline-primary refresh" :disabled="safesportRefreshing" @click="loadRostersInfo(1)" class="mr-1" v-if="this.$route.meta.listGuestOnly != true && subdomain!='alliancefastpitch'">
                                    <i class="fa" :class="safesportRefreshing ? 'fa-circle-notch fa-spin' : 'fas fa-sync-alt'"></i> Safe Sport Refresh
                                </b-button>
                                <b-button class="outline-primary btn-darkblue mr-1" :class="{ 'filter-applied': filtersApplied }" v-b-modal.modal-member-report>
                                    <i class="fas fa-sliders-h"></i> Filters
                                </b-button>
                                <b-button class="outline-primary btn-darkblue" @click="refreshList" v-if="filtersApplied">
                                    <i class="fas fa-redo"></i> Reset
                                </b-button>
                                </div>
                                </div>
                            </div>
                        </div>
                      <div style="margin-top: 22px; margin-left: 25px;" class="addnewbutn">
                        <b-button-group>
                          <b-dropdown right text="With selected rosters... ">
                            <b-dropdown-item @click="validateRosterSelection('email')">Send a message</b-dropdown-item>
                            <b-dropdown-item v-if="club_member_delete_option" @click="validateRosterSelection('delete')">Delete</b-dropdown-item>
                          </b-dropdown>
                        </b-button-group>
                      </div>
                      <div class="card-body addnewbutn">
                        <TableLoaderV1 v-if="!tableEnable" />
                            <div class="memberdetail-section" v-show="tableEnable">
                                <div class="row mb-3">
                                    <div class="col-md-12">
                                        <table id="list_table" class="table table-sm table-hover" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th> <div class="d-flex" style="width: 100px;"> <input type="checkbox" id="selectall" style="margin-left: 13px; margin-right:4px;" />Select All</div></th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Date of Birth</th>
                                                    <th>Age</th>
                                                    <th>Member#</th>
                                                    <th>Membership <br>Type</th>
                                                    <th>Membership <br>Status / <br>Expiration<a href="javascript:void(0);" v-if="getSiteProps('clubMemberRoster.membershipStatusIcon')" v-b-modal.statusInfo><img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" /></a></th>
                                                    <th class="none">Primary <br>Sport <br>Discipline</th>
                                                    <th>Safe Sport <br>Training <br>Status / <br>Expiration</th>
                                                    <th>
                                                        Background <br>Screening <br>Status / <br>Expiration
                                                        <template v-if="getSiteProps('tooltips.backgroundScreening')">
                                                            <div class="d-inline" id="bgscreen-tooltip" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }">
                                                                <img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" />
                                                            </div>
                                                            <b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip" class="white">
                                                                <p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p>
                                                            </b-tooltip>
                                                        </template>
                                                    </th>
                                                    <th class="none">Official Ratings</th>
                                                    <th>Roster Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr><td colspan="12">&nbsp;</td></tr>
                                                <tr><td colspan="12">&nbsp;</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row juniordevlopment">
                                    <div class="col-md-12 pl-0 pr-0">
                                        <div class="primary-contact d-none">
                                            <b-overlay :show="showLoader" no-wrap></b-overlay>
                                            <b-table
                                                id="my-table"
                                                :items="rostersInfo"
                                                :sort-by.sync="sortBy"
                                                :sort-desc.sync="sortDesc"
                                                :fields="visibleFields"
                                                hover
                                                show-empty
                                                sticky-header="450px"
                                                responsive="sm"
                                            >
                                                <template #head(member_status)="data">
                                                    {{ data.label }} <a href="javascript:void(0);" v-if="getSiteProps('clubMemberRoster.membershipStatusIcon')" v-b-modal.statusInfo><img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" /></a>
                                                </template>
                                                <template #head(background_screening)="data">
                                                    {{ data.label }}
                                                    <template v-if="getSiteProps('tooltips.backgroundScreening')">
                                                        <div class="d-inline" id="bgscreen-tooltip" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }">
                                                            <img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" />
                                                        </div>
                                                        <b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip" class="white">
                                                            <p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p>
                                                        </b-tooltip>
                                                    </template>
                                                </template>
                                                <template #empty="scope">
                                                    <h4>{{ scope.emptyText }}</h4>
                                                </template>
                                                <!-- <template #head(selected)="row"> -->
                                                <template>
                                                    <b-form-group class="text-center">
                                                        <b-form-checkbox
                                                            @click.native.stop
                                                            :indeterminate="indeterminate"
                                                            @change="toggleAll"
                                                        ></b-form-checkbox>
                                                    </b-form-group>
                                                </template>
                                                <template #cell(selected)="row">
                                                    <b-form-group>
                                                        <input type="checkbox" v-model="selected" :value="row.item.id" :class="'bulkActChk'+row.index" />
                                                    </b-form-group>
                                                </template>
                                                <template #cell(official_rating)="row">
                                                    <b-link :href='"/club/roster/officialratings/"+row.item.official_rating'>Rating Details</b-link>
                                                </template>
                                              <template #cell(roster_status)="row">
                                                {{rosterStatus[row.item.roster_status]}}
                                              </template>
                                                <template #cell(actions)="row">
                                                    <div class="text-left py-0 align-middle">
                                                        <div class="btn-group btn-group-sm">
                                                            <button v-if="subdomain != 'alliancefastpitch'" class="btn btn-danger ml-1"
                                                                    @click="deleteRoster(row.item.id)"><i
                                                                class="fas fa-trash"></i></button>
                                                          <button v-if="subdomain == 'alliancefastpitch' && isTransferEnable == 1" class="btn btn-danger ml-1" @click="transferRoster(row.item.id, row.item.dob)"
                                                                  >
                                                                  <i
                                                              class="fas fa-share"></i></button>
                                                          <button v-if="row.item.team_roster_status == 0"  class="btn btn-danger ml-1"
                                                                  @click="hideRoster(row.item.id,row.index,1)"><i
                                                              class="fas fa-eye"></i></button>
                                                        </div>
                                                    </div>
                                                </template>
                                            </b-table>
                                        </div>
                                        <b-modal id="statusInfo" size="xl" title="Membership Status Types" hide-footer>
                                            <div class="table-responsive">
                                                <table class="table table-sm table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Status</th>
                                                            <th>What This Means</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Current</td>
                                                            <td>Paid dues, Signed Waiver, Completed Safe Sport training </td>
                                                            <td>All membership requirements are met and may participate in all sanctioned activities.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Expired</td>
                                                            <td>Dues not paid</td>
                                                            <td>Member needs to renew as an active (insured) member to be eligible to participate in sanctioned activity.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Waiting – Need Safe Sport</td>
                                                            <td>Paid dues, Signed Waiver but has not completed Safe Sport training </td>
                                                            <td>Membership is pending successful completion of Safe Sport training within 30 days of joining as a new member. May still participate in sanctioned activities during this period. </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pending – Safe Sport </td>
                                                            <td>Paid dues, Signed Waiver but has not completed Safe Sport training after 30 days</td>
                                                            <td>Not eligible to participate in sanctioned events (tournaments) until Safe Sport training is completed. The 30-day grace period to complete Safe Sport training after joining as a new member has expired. </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pending – Signed Waiver </td>
                                                            <td>Paid dues but has not signed Waiver</td>
                                                            <td>Membership is pending signed Annual Membership Waiver. Not eligible to participate in sanctioned activity until waiver is signed. Please go into your individual membership dashboard to sign the Waiver.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pending – Safe Sport & Signed Waiver</td>
                                                            <td>Paid dues but has not signed Waiver or completed Safe Sport training</td>
                                                            <td>Membership is pending successful completion of Safe Sport Training and signed Annual Membership Waiver. Not eligible to participate until Waiver is signed. Please go into your individual membership dashboard to sign the Waiver. New members have 30 days to complete Safe Sport training.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Suspended</td>
                                                            <td>N/A</td>
                                                            <td>Ineligible to participate in any capacity with any USA Water Ski & Wake Sports club or in any USA Water Ski & Wake Sports sanctioned activity.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Deceased</td>
                                                            <td>N/A</td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </b-modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <member-lookup v-on:member-lookup-data="addClubMemberRoster" :memberType="this.$route.meta.listGuestOnly ? 'guest' : ''"></member-lookup>

            <b-modal
                id="modal-bulk-email"
                ref="modal"
                title="Send Email"
                size="lg"
                :ok-title="btnTitle"
                :ok-disabled="btnDisabled"
                @show="resetBulkEmailModal"
                @hidden="resetBulkEmailModal"
                @ok="handleSendEmailBtn"
            >
                <form ref="form" @submit.stop.prevent="bulkEmailAction">
                    <div class="row">
                        <div class="col-md-6">
                            <b-form-group
                                label="From Name"
                                label-for="from_name"
                                :state="nameState"
                            >
                                <b-form-input
                                    id="from_name"
                                    v-model="bulkEmail.from_name"
                                    :state="nameState"
                                    required
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group
                                label="From Email"
                                label-for="from_email"
                                :state="emailState"
                            >
                                <b-form-input
                                    id="from_email"
                                    v-model="bulkEmail.from_email"
                                    :state="emailState"
                                    required
                                    type="email"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                    </div>


                    <b-form-group
                        label="Subject"
                        label-for="subject"
                        
                        :state="subState"
                    >
                        <b-form-input
                            id="subject"
                            v-model="bulkEmail.subject"
                            :state="subState"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        label="Message"
                        label-for="message"
                        
                        :state="msgState"
                    >
                        <ckeditor
                            id="message"
                            v-model="bulkEmail.message"
                            placeholder="Enter message"
                            rows="3"
                            max-rows="6"
                            :config="editorConfig"
                            required
                        ></ckeditor>
                    </b-form-group>
                </form>

            </b-modal>
          <b-modal id="playerTransfer" ref="playerTransfer" title="Player Transfer"
                   @show="resetPlayerTransfer"
                   @hidden="resetPlayerTransfer"
                   @ok="handlePlayerTransfer"
          >
            <form ref="form" >
              <div class="form-group">
                <label for="league" class="form-label">Leagues <sup class="text-danger">*</sup></label>
                <select class="form-control" v-model="playerTransferForm.league" id="league" @change="getTeam()" >
                  <option value="">Select</option>
                  <option  v-for="(leagueData,key) in teamLeague" :key="key" :value="leagueData.id">{{ leagueData.name }}</option>
                </select>
                <span class="text-sm text-danger"></span>
              </div>

              <div class="form-group">
                <label for="age_division" class="form-label">Division <sup class="text-danger">*</sup></label>
                <select class="form-control" @change="getTeam()" id="age_division" v-model="playerTransferForm.age_division"  name="playerTransferForm.age_division" >
                  <option value="">Select</option>
                  <option  v-for="(listOfAgeDivision,key) in divisions_list" :key="key"  :name="listOfAgeDivision.text" :value="listOfAgeDivision.id">{{ listOfAgeDivision.text }}</option>
                </select>
                <span class="text-sm text-danger"></span>
              </div>

              <div class="form-group">
                <label for="leagueTeam" class="form-label">Team <sup class="text-danger">*</sup></label>
                <select class="form-control" id="leagueTeam" v-model="playerTransferForm.team" >
                  <option value="">Select</option>
                  <option value="wait">Wait List</option>
                  <option value="free">Free Agents</option>
                  <option  v-for="(TeamData,key) in leagueTeam" :key="key" :value="TeamData.id">{{ TeamData.text }}</option>
                </select>
                <span class="text-sm text-danger"></span>
              </div>
              <div class="form-check">
                <input class="form-check-input" v-model="playerTransferForm.notify" type="checkbox" value="" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  Notify
                </label>
              </div>
            </form>
          </b-modal>
          <b-modal id="modal-club-report" v-bind:hide-footer="true" ref="modal-club-report" size="lg"  title="Advanced Search">
            <form ref="form" method="post" @submit.prevent="advancedSearch()">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Search Format</label>
                    <select id="membership_id" name="membership_id" class="form-control" @change="onChange()" v-model="searchForm.searchFormat">
                      <option  value="<" >{{ 'Less then' }}</option>
                      <option  value="<=" >{{ 'Less than or equal to' }}</option>
                      <option  value=">" >{{ 'Greater then' }}</option>
                      <option  value=">=" >{{ 'Greater than or equal to' }}</option>
                      <option  value="between" >{{ 'Between' }}</option>
                    </select>
                  </div>
                  <p class="text-sm text-danger" v-if="errors.searchFormat">
                    {{ errors.searchFormat }}</p>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Membership Expiration Start Date</label>
                    <div class="input-group birthdate">
                      <div class="birthdaypicker">
                        <date-pick
                            v-model="searchForm.MemberExpirationStartDate"
                            :format="'MM/DD/YYYY'"
                            :displayFormat="'MM/DD/YYYY'"
                            v-mask="'##/##/####'"
                            :parseDate="parseDatePick"
                        ></date-pick>
                      </div>
                    </div>
                    <input id="registration_start_date" type="hidden" class="form-control" :value="registration_start_date_1">
                  </div>
                  <p class="text-sm text-danger" v-if="errors.MemberExpirationStartDate">
                    {{ errors.MemberExpirationStartDate }}</p>
                </div>
                <div class="col-md-6" v-if="datepicker2 ==  true">
                  <div class="form-group">
                    <label class="form-label">Membership Expiration End Date</label>
                    <div class="input-group birthdate">
                      <div class="birthdaypicker">
                        <date-pick
                            v-model="searchForm.MemberExpirationEndDate"
                            :format="'MM/DD/YYYY'"
                            :displayFormat="'MM/DD/YYYY'"
                            v-mask="'##/##/####'"
                            :parseDate="parseDatePick"
                        ></date-pick>
                      </div>
                    </div>
                    <div class="text-sm text-danger">{{ errors.MemberExpirationEndDate }}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Membership Status</label>
                    <select id="membership_id" name="membership_id" class="form-control" v-model="searchForm.MembershipStatus">
                      <option  value="" >{{ 'Select' }}</option>
                      <template v-for="(id, val) in membershipStatus">
                        <option v-if="id==''"  :value="val" :key="val">{{ '-NA-' }}</option>
                        <option v-if="id!=''" :value="val" :key="val">{{ id }}</option>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Safe Sport Status</label>
                    <select id="membership_id" name="membership_id" class="form-control" v-model="searchForm.safeSportStatus">
                      <option  value="">{{ 'Select' }}</option>
                      <template v-for="(id, val) in safesportStatus">
                        <option :value="val" :key="val">{{ id }}</option>
                      </template>
                    </select>
                  </div>
                </div>

                <div class="col-md-12 modal-searchbtn text-center">
                  <div class="form-group">
                    <button type="submit" class="btn btn-primary btn-blue">{{ button.SearchButton }}</button>
                  </div>
                  <div class="text-sm text-danger">{{ errors.not_found }}</div>
                </div>
              </div>
            </form>
          </b-modal>


            <b-modal id="modal-member-report" v-bind:hide-footer="true" ref="modal-member-report" size="lg"  title="Advanced Search">
                <form ref="form" method="post" @submit.prevent="advancedSearch()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Membership Expiration (From)</label>
                                <div class="input-group birthdate">
                                    <div class="birthdaypicker">
                                        <date-pick
                                            v-model="searchForm.membership_expiration_from"
                                            :format="'MM/DD/YYYY'"
                                            :displayFormat="'MM/DD/YYYY'"
                                            v-mask="'##/##/####'"
                                            :parseDate="parseDatePick"
                                        ></date-pick>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Membership Expiration (To)</label>
                                <div class="input-group birthdate">
                                    <div class="birthdaypicker">
                                        <date-pick
                                            v-model="searchForm.membership_expiration_to"
                                            :format="'MM/DD/YYYY'"
                                            :displayFormat="'MM/DD/YYYY'"
                                            v-mask="'##/##/####'"
                                            :parseDate="parseDatePick"
                                        ></date-pick>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="subdomain!='alliancefastpitch'">
                            <div class="form-group">
                                <label class="form-label">Safe Sport Expiration (From)</label>
                                <div class="input-group birthdate">
                                    <div class="birthdaypicker">
                                        <date-pick
                                            v-model="searchForm.safe_sport_expiration_from"
                                            :format="'MM/DD/YYYY'"
                                            :displayFormat="'MM/DD/YYYY'"
                                            v-mask="'##/##/####'"
                                            :parseDate="parseDatePick"
                                        ></date-pick>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="subdomain!='alliancefastpitch'">
                            <div class="form-group">
                                <label class="form-label">Safe Sport Expiration (To)</label>
                                <div class="input-group birthdate">
                                    <div class="birthdaypicker">
                                        <date-pick
                                            v-model="searchForm.safe_sport_expiration_to"
                                            :format="'MM/DD/YYYY'"
                                            :displayFormat="'MM/DD/YYYY'"
                                            v-mask="'##/##/####'"
                                            :parseDate="parseDatePick"
                                        ></date-pick>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Membership Status</label>
                                <!-- <input type="text" class="form-control"> -->
                                <!-- <select id="status" name="status" class="form-control" v-model="searchForm.membership_status">
                                    <option value="">All</option>
                                    <template v-for="(value, id) in membershipStatus">
                                        <option v-if="id > 0" :value="value" :key="id">{{ value }}</option>
                                    </template>
                                </select> -->
                                <Select2 v-model="searchForm.membership_status" name="status" id="status"
                                :options="membershipStatusSelect2"
                                :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                            </div>
                        </div>
                        <div class="col-md-6" v-if="subdomain=='alliancefastpitch'">
                            <div class="form-group">
                                <label class="form-label">Roster Status</label>
                                <Select2 v-model="searchForm.roster_status" name="roster_status" id="roster_status"
                                :options="roster_status"
                                :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                            </div>
                        </div>
                        <div class="col-md-6" v-if="subdomain!='alliancefastpitch'">
                            <div class="form-group">
                                <label class="form-label">Safe Sport Status</label>
                                <!-- <input type="text" class="form-control"> -->
                                <!-- <select id="safe_sport_status" name="safe_sport_status" class="form-control" v-model="searchForm.safe_sport_status">
                                    <option value="">All</option>
                                    <option value="Not Started">Not Started</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Complete">Complete</option>
                                    <option value="Expired">Expired</option>
                                </select> -->
                                <Select2 v-model="searchForm.safe_sport_status" name="safe_sport_status" id="safe_sport_status"
                                :options="[{ id: 0, text: 'Not Started' },{ id: 1, text: 'In Progress' },{ id: 3, text: 'Complete' },{ id: 13, text: 'Expired' }]"
                                :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                            </div>
                        </div>
                        <div class="col-md-12 modal-searchbtn text-center">
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary btn-blue">Search</button>
                            </div>
                        </div>
                    </div>
                </form>
            </b-modal>

            <b-modal id="export-confirmation" size="md" title="Export" ok-only ok-title="Export" @ok="exportPDF">
                <div class="form-group">
                    <label for="exclude_expired" class="control-label">You want to exclude expired members ?</label><br>
                    <input type="radio" name="exclude_expired" value="1" v-model="exclude_expired"> Yes <br>
                    <input type="radio" name="exclude_expired" value="0" v-model="exclude_expired"> No <br>
                </div>
            </b-modal>
        </div>
    </div>
</template>
<script>
import MemberLookup from "./components/MemberLookup";
import CustomAlert from "../../CustomAlert";
import axios from 'axios';
import * as $ from 'jquery';
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import "datatables.net-fixedheader/js/dataTables.fixedHeader.js";
import DatePick from "vue-date-pick";

import moment from "moment";
import TableLoaderV1 from "../../loaders/TableLoaderV1";
const validateName = name => {
  if (name.length || name != "") {
    return { valid: true, error: null };
  }
  return { valid: false, error: null };
};
export default {
    name: "clubroaster",
    components: {TableLoaderV1, MemberLookup, CustomAlert, DatePick},
    data() {
        return {
            titleLabel:this.$route.meta.title,
            rostersInfo:[],
            sortBy: 'last_name',
            sortDesc: false,
            safesportRefreshing: false,
            tblFields: [
                { key: 'selected', sortable: false },
                // { key: 'full_name', sortable: true, label: 'Name'  },
                { key: 'last_name', sortable: true, label: 'Last Name'  },
                { key: 'first_name', sortable: true, label: 'First Name'  },
                { key: 'confirmation_code', sortable: true, label: 'Member #' },
                { key: 'membership_name', sortable: true, label: "Membership Type" },
                { key: 'membership_endtate', sortable: true, label: "Membership Expiration" },
                { key: 'member_status', sortable: true, label: 'Membership Status' },
                { key: 'sports_discipline', sortable: true, label: 'Primary Sport Discipline' },
                { key: 'safesport', sortable: false, label:"Safe Sport Training Status / Expiration" },
                { key: 'background_screening', sortable: false, label:"Background Screening Status / Expiration" },
                { key: 'official_rating', sortable: false, label: "Official Ratings" },
                { key: 'roster_status', sortable: false, label: "Roster Status" },
                { key: 'actions', sortable: false },
            ],
            selected: [],
            allSelected: false,
            indeterminate: false,
            selectedRosters:[],
            bulkEmail:{
                users:[],
                subject:"",
                from_name:"",
                from_email:"",
                message:""
            },
            btnDisabled:false,
            btnTitle:'Send Email',
            subState:null,
            nameState:null,
            emailState:null,
            msgState:null,
            exportProgress: false,
            exportPDFProgress: false,

            teamdata:null,
            url:null,
            allProgress:false,
            table: null,
            filtersApplied: false,
            searchForm: {
                membership_status: [],
                roster_status: [],
                membership_expiration_from: null,
                membership_expiration_to: null,
                safe_sport_status: [],
                safe_sport_expiration_from: null,
                safe_sport_expiration_to: null
            },
          rosterStatus:{
            1:'Active',
            2:'Wait List',
            3:'Free Agent',
            4:'Pending Transfer'
          },
            showAllhidden:true,
            showActive:false,
            status: '',
            button: {
             SearchButton: 'Search'
            },

           datepicker2:true,
          disciplines_list:[],
          playerTransferForm:{
            league:'',
            team:'',
            notify:'',
            member_id:'',
            age_division:'',

          },
          isTransferEnable:0,
          exclude_expired: 0,
          UpdateCLub:{},
          divisions_lists:[],
          divisions_list:[],
          club_reg_status:"Current",
          teamOptions:false,
          roster_status:[{ id: 1, text: 'Active' },{ id: 2, text: 'Wait List' },{ id: 4, text: 'Pending Transfer' }],
          current_members_default: null,
          tableEnable: false,
          editorConfig: {
            toolbar: [
              {
                name: 'document',
                items: [
                  'Source', 'Heading',
                  '-', 'Bold', 'Italic', 'Underline','NumberedList', 'BulletedList',
                  '-',
                  'Outdent', 'Indent',
                  '-',
                  'Undo', 'Redo',
                ]
              },
              { name: 'styles', items: [ 'Format', 'Font', 'FontSize' ] },
              { name: 'links', items: ['Link', 'Unlink'] },
            ],
          }
        }
    },
    methods: {
        toggleAll(checked) {
            this.selectedRosters=[];
            for(const roster of this.rostersInfo){
                this.selectedRosters.push(roster.id);
            }
            this.selected = checked ? this.selectedRosters.slice() : [];
        },
        resetAlert(){
            this.displayAlert = false;
        },
        loadRostersInfo(value = 0){
            if(value){
                this.safesportRefreshing = true;
            }
            if(this.table == null){
                var self = this;
                this.tableEnable = true;
                if(self.current_members_default) {
                  this.filtersApplied = true;
                  self.searchForm.membership_status = [1];
                }
                this.table = $('#list_table').DataTable({
                    rowReorder: {
                        selector: 'td:nth-child(2)'
                    },
                    dom: "lfrtip",
                    // fixedHeader: {
                    //     header: true,
                    //     headerOffset: 70
                    // },
                    scrollCollapse: true,
                    scrollY: '50vh',
                    processing: true,
                    serverSide: true,
                    bFilter: true,
                    responsive: true,
                    pageLength: 200,
                    "aLengthMenu": [[10, 50, 100, 200],
							                    [10, 50, 100, 200]],
                    ajax: {
                        url: process.env.VUE_APP_URL + "api/club/roster",
                        type: 'get',
                        headers: this.clubHeaders,
                        data: function (d) {
                            d.dt = 1;
                            d.refresh = self.safesportRefreshing;
                            d.filters = self.searchForm;
                            d.guest = self.$route.meta.listGuestOnly;
                        }
                    },
                    columns: [
                        { data: 'id', name: 'id', className:'text-center', orderable: false,render: (data,type,row,meta)=>{
                            return `<input type="checkbox" value="${row.id}" class="bulkActChk" id="bulkActChk${row.id}" />`;
                        }},
                        { data: 'full_name', name: 'full_name', render: (data, type, row, meta)=> {
                            var contact = row.contact_type?row.contact_type['contact_type']:'N/A';
                            if(contact != 'N/A' && contact == 6 && this.subdomain == 'waterski'){
                              return data+'<br><span style="color:red">*** Show Director ***</span>';
                            }else if(contact != 'N/A' && contact == 7 && this.subdomain == 'waterski'){
                              return data+'<br><span style="color:red">*** Ast Show Director ***</span>';
                            }else{
                              return data;
                            }
                        }},
                        { data: 'email', name: 'email', render: (data)=>{return data || "N/A";}},
                        { data: 'dob', name: 'dob', render: (data)=>{return data || "N/A";}},
                        { data: 'age', name: 'age', render: (data)=>{return data || "N/A";}},
                        { data: 'confirmation_code', name: 'confirmation_code', render: (data)=>{return data || "N/A";}},
                        { data: 'membership_name', name: 'membership_name', render: (data,type,row,meta)=>{
                            if(row.membership_info_link)
                                return `<a href="https://www.teamusa.org/usa-water-ski/membership-types" target="_blank">${data}</a>` || "N/A";
                            else
                                return data || "N/A";
                        }},
                        { data: 'member_status', name: 'member_status',width:100, render: (data)=>{return data || "N/A";}},
                        { data: 'sports_discipline', name: 'sports_discipline', render: (data)=>{return data || "N/A";}},
                        { data: 'safesport', name: 'safesport', width:100,render: (data)=>{return data || "N/A";}},
                        { data: 'background_screening', name: 'background_screening',width:100, render: (data)=>{return data || "N/A";}},
                        { data: 'official_rating', name: 'official_rating', render: (data,type,row,meta)=>{
                            return `<a href="/club/roster/officialratings/${row.official_rating}">Rating Details</a>`;
                        }},
                        { data: 'roster_status', name: 'roster_status', render: (data)=>{return self.rosterStatus[data] || "N/A";}},
                        { data: 'action', name: 'action', searchable:false},
                    ],
                    columnDefs: [
                      { targets: 3, visible: self.subdomain!='waterski' },
                      { targets: 4, visible: self.subdomain=='waterski' },
                        { targets: 9, visible: self.subdomain!='alliancefastpitch' },
                        { targets: 10, visible: self.subdomain!='alliancefastpitch' },
                        { targets: 11, visible: self.subdomain!='alliancefastpitch' },
                        { targets: 12, visible: self.subdomain=='alliancefastpitch' },
                        { targets: 13, visible: self.subdomain=='alliancefastpitch' },
                    ],
                    fnDrawCallback: function(){
                      if(self.safesportRefreshing === true){
                        self.safesportRefreshing = false;
                      }
                    }
                });
            } else {
                this.table.draw();
            }
        },
        addClubMemberRoster(data){
            this.displayAlert = false;
            if(data)
            {
            axios.post(this.basePath + 'api/club/roster', data, {headers: this.clubHeaders})
                .then(function (response) {
                    this.alertMessage = "Club Rosters added successfully";
                    this.displayAlert = true;
                    this.loadRostersInfo();
                }.bind(this))
                .catch(function (error) {
                    console.log(error);
                });
            }
        },
        deleteRoster(id){
            this.displayAlert = false;
            this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this member from the Club Roster.', {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if(String(value)=="true") {
                        axios.delete(this.basePath + 'api/club/roster/' + id, {headers: this.clubHeaders})
                            .then(function (response) {
                                this.alertMessage = "Club Rosters deleted successfully";
                                this.displayAlert = true;
                                this.loadRostersInfo();
                            }.bind(this))
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },
        bulkDelAction(){
            this.displayAlert = false;
            this.$bvModal.msgBoxConfirm('Please confirm that you want to delete the selected members from the Club Member Rosters.', {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if(String(value)=="true") {
                        axios.post(this.basePath + 'api/club/roster/delete', this.selected, {headers: this.clubHeaders})
                            .then(function (response) {
                                this.alertMessage = "Club Rosters deleted successfully";
                                this.displayAlert = true;
                                this.selected=[];
                                this.indeterminate=false;
                                this.allSelected = false;
                                this.loadRostersInfo();
                                this.showLoader = false;
                            }.bind(this))
                            .catch(function (error) {
                                console.log(error);
                            });
                    }else{
                        this.showLoader = false;
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },
        validateRosterSelection(typ){
            this.showLoader = true;
            if(this.selected.length>0) {
                if(typ=="email") {
                    this.$bvModal.show('modal-bulk-email');
                }else if(typ=="delete"){
                    this.bulkDelAction();
                }
            }else{
                this.showLoader = false;
                this.$bvModal.msgBoxOk('Select at least one roster', {
                    title: 'Required',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                })
                    .then(value => {})
                    .catch(err => {})
            }
        },
        resetBulkEmailModal() {
            this.bulkEmail.subject = "";
            this.bulkEmail.from_name = "";
            this.bulkEmail.from_email = "";
            this.bulkEmail.message = "";
            this.subState = null;
            this.nameState = null;
            this.emailState = null;
            this.msgState = null;
            this.showLoader = false;
        },
        handleSendEmailBtn(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.bulkEmailAction();
        },
        async bulkEmailAction() {
            if (!this.checkFormValidity()) {
                return;
            }
            this.btnDisabled = true;
            this.btnTitle = 'Sending...';
            this.bulkEmail.users = this.selected;
            await axios.post(this.basePath + 'api/club/roster/email', this.bulkEmail, {headers: this.clubHeaders})
                .then(function (response) {
                    this.alertMessage = "Email message sent successfully to the selected club member rosters";
                    this.displayAlert = true;
                    //this.selected=[];
                    this.allSelected = false;
                    this.indeterminate=false;
                    this.showLoader = false;
                }.bind(this))
                .catch(function (error) {
                    console.log(error);
                    this.btnDisabled = false;
                    this.btnTitle = 'Send Email';
                });

            this.$nextTick(() => {
                this.btnDisabled = false;
                this.btnTitle = 'Send Email';
                this.$bvModal.hide('modal-bulk-email');
            });
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity();
            const report = this.$refs.form.reportValidity();
            this.subState = valid
            this.nameState = valid
            this.emailState = valid
            this.msgState = valid
            return valid
        },
      exportPDF(){
            this.exportPDFProgress = true;
            axios.post(
                this.basePath + 'api/club/member-rosters/export',
                { filters: this.searchForm, guest: this.$route.meta.listGuestOnly, exclude_expired: this.exclude_expired },
                { headers: this.clubHeaders, responseType: 'blob' }
            )
            .then(function (response) {
                // window.open(URL.createObjectURL(response.data));
                this.exportPDFProgress = false;
                this.exclude_expired = 0;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'member-roster.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }.bind(this))
            .catch(function (error) {
                this.exportPDFProgress = false;
                console.log(error);
            });
        },
        advancedSearch(){
            this.current_members_default = false;
            this.table.draw();
            this.filtersApplied = true;
            this.$refs['modal-member-report'].hide();
        },
        refreshList(event){
          this.current_members_default = false;
            this.searchForm = { 
                membership_status: [],
                roster_status: [],
                membership_expiration_from: null,
                membership_expiration_to: null,
                safe_sport_status: [],
                safe_sport_expiration_from: null,
                safe_sport_expiration_to: null
            };
             $("#selectall").prop("checked", false);
            this.table.ajax.reload();
            this.filtersApplied = false;
      },
      transferRoster(id,dob){
        this.$refs['playerTransfer'].show()
        this.playerTransferForm.member_id = id;
      },
      getLeagues()
      {
        axios.get(this.basePath+"api/active_league")
            .then(response => {
              this.teamLeague = response.data.data;
              console.log("league",this.teamLeague);
            })
            .catch(err => {
              // Manage the state of the application if the request
              // has failed
            })
      },
      getTeam()
      {
        let leagueId = this.playerTransferForm.league;
        let teams = this.teamLeague[leagueId].team;
        this.leagueTeam = [];
        teams.forEach(code => {
          if(this.playerTransferForm.age_division == code.age_division){
            this.leagueTeam.push({ id: code.id, text: code.name });
          }
        });
      },
      handlePlayerTransfer(evt)
      {
        evt.preventDefault();
        axios.post(this.basePath + 'api/club/transferPlayer/request', this.playerTransferForm, {headers: this.clubHeaders})
            .then(function (response) {
              this.alertMessage =  response.data.message;
              this.displayAlert = true;
              this.loadRostersInfo();
              this.showLoader = false;
              this.$refs['playerTransfer'].hide()
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            });
      },
      resetPlayerTransfer()
      {
        this.playerTransferForm.league = this.UpdateCLub.league;
        this.playerTransferForm.team = '';
        this.playerTransferForm.notify = '';
        this.playerTransferForm.member_id = '';
        this.playerTransferForm.age_division=this.UpdateCLub.ageDivision;

        },
      getDiscipline() {
        axios.get(this.basePath + "api/getDisciplines")
            .then(response => {
              this.disciplines = response.data.data;
              let discipline_codes = Object.keys(this.disciplines);
              if (discipline_codes.length && this.disciplines_list.length == 0) {
                discipline_codes.forEach(code => {
                  this.disciplines_list.push({
                    id: code,
                    text: this.disciplines[code] == "NA - N/A" ? "N/A" : this.disciplines[code]
                  });
                });
              }
              this.ageDivisionOPt = this.disciplines_list;
            })
            .catch(err => {
              // Manage the state of the application if the request
              // has failed
            })
      },
      checkTransferEnable(){
        axios.post(this.basePath + "api/get/playerTransfer/enable", this.updInsurance, {headers: this.clubHeaders})
            .then(
                function (response) {
                  const resp = response.data.data;
                  this.isTransferEnable = resp.flag;
                }.bind(this)
            ).catch(function (error) {
          console.log(error);
        });
      },
      loadClubInfo() {
        axios.get(this.basePath + "api/club/info", { headers: this.clubHeaders })
            .then((response) => {

              const resp = response.data.data;

              this.UpdateCLub.ageDivision = resp.ageDivision.id;
              this.UpdateCLub.league = resp.memberLeageue.id;
              this.club_reg_status = resp.registration.reg_status;

              this.playerTransferForm.age_division = this.UpdateCLub.ageDivision;
              this.playerTransferForm.league = this.UpdateCLub.league;
              this.getTeam();
              //alliance fast pitch profile handler
              if(!this.getSiteProps('general.member_profile_addition_info') && this.getSiteProps('general.member_profile_addition_info') != undefined){
                this.clubAdditionalInfoView = false;
              }

            })
            .catch((error) => {
              console.log(error);
            });
      },
      isUnderAgeDivision(birth_date){
        let age = this.getAgeSeasonWise(birth_date);
        this.divisions_list=[];
        this.teamOptions = this.getSiteProps('memberRegistration.team.fields.club_info.age_division.options');
        var valObj = this.teamOptions.filter(function (elem) {
          if (elem.validAge >= age && age > 12) return elem.value;
          else if(elem.validAge >= age && elem.validAge <= 12) return elem.value;
        });
        if (valObj.length > 0) {
          age = Math.floor(age);
          valObj.forEach(code => {
            this.divisions_list.push({ id: code.id, text: code.value == "NA - N/A" ? "N/A" : code.value });
          });
        }
        this.ageDivisionOPt = this.divisions_list;
        console.log('date',this.ageDivisionOPt);
      },
      exportCsv(){
        this.data = null;
          if(this.filtersApplied === true){
            this.data = JSON.stringify(this.searchForm);
            console.log('filter apply',this.filtersApplied,this.data);
          }
        var url = this.csvurl;
        var self = this;
        console.log('filter not apply',this.filtersApplied,this.data);
        $.ajax({
          url: url,
          type: 'post',
          headers: self.clubHeaders,
          "data": this.data,
          beforeSend: function(){
            self.exportProgress = true;
          },
          "success": function(res, status, xhr) {
            self.exportProgress = false;
            if(res.file_url != undefined) window.open(res.file_url, '_blank');
          },
          error: function(err){
            console.log(err);
            self.exportProgress = false;
          }
        });
      },
      downloadOfficials(){
        this.exportPdfProgress = true;
        let url = this.pdfurl;
        this.data = null;
        if(this.filtersApplied === true){
          this.data = this.searchForm;
          console.log('filter apply',this.filtersApplied,this.data);
        }
        axios.post(url,this.data,{headers:this.clubHeaders, responseType: 'arraybuffer'})
            .then(response => {
              console.log(response.data);
              let blob = new Blob([response.data], { type: 'application/pdf' });
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = 'rosterPdf.pdf';
              link.click();
              link.remove();
              this.exportPdfProgress = false;
            }).catch((error)=>{
          console.log("error download");
          this.exportPdfProgress = false;
        });
      },
      hideRoster(member_id,index,type){
        this.showLoader = true;
         console.log(member_id);
         this.rostersInfo.splice(index, 1);
         this.teamdata = JSON.parse(localStorage.getItem('team'));
         console.log(this.teamdata.id);
        axios.get(this.basePath + 'api/club/roster/hide/'+member_id+'/'+this.teamdata.id+'/'+type, {headers: this.clubHeaders})
            .then(function (response) {
              if(type == 0){
                this.loadRostersInfo();
              }else{
                this.loadRostersInfo(type);
              }
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            });
      },
      validate() {
        this.errors = {};
        if(this.searchForm.MemberExpirationStartDate != undefined || this.searchForm.MemberExpirationEndDate != undefined) {
          if(this.searchForm.searchFormat === undefined) {
            this.errors.searchFormat = "This field is required";
            return this.valid = false;
          }
        }
        if(this.searchForm.searchFormat != undefined){
              if(this.searchForm.MemberExpirationStartDate === undefined){
                  this.errors.MemberExpirationStartDate  = "This field is required";
                  return this.valid =  false;
              }
            else if(this.searchForm.searchFormat == "between"){
              if(this.searchForm.MemberExpirationStartDate === undefined){
                this.errors.MemberExpirationStartDate  = "This field is required";
                return this.valid =  false;
              }
              if(this.searchForm.MemberExpirationEndDate === "" || this.searchForm.MemberExpirationEndDate === undefined){
                this.errors.MemberExpirationEndDate  = "This field is required";
                return this.valid =  false;
              }
            }
            return this.valid =  true;
        }
        return this.valid = true;
      },
      getSafeSportStatus(){
        axios.get(this.basePath+'api/getSafeSportStatus')
            .then(response => {
              this.safesportStatus = response.data.data;
            })
            .catch(err => {
              console.log(err);
            })
      },
      onChange(){
        if(this.searchForm.searchFormat !='' && this.searchForm.searchFormat != 'between'){
          this.datepicker2 = false;
        }else{
          this.datepicker2 = true;
        }
      },
      exportConfirm(){
          if(this.current_members_default === false) {
            this.$bvModal.show('export-confirmation')
          } else {
            this.exportPDF();
          }
      }
    },
    mounted() {
        this.showLoader=true;
        const retry = setInterval(() => {
          this.current_members_default = this.getSiteProps('clubMemberRoster.current_members_default');
          if(this.current_members_default !== undefined) {
            this.loadRostersInfo();
            clearInterval(retry);
          }
        }, 2000);
        this.getSafeSportStatus();
        this.getLeagues();
        if(this.subdomain != 'alliancefastpitch') {
          this.getDiscipline();
        }
        if(this.subdomain == 'alliancefastpitch'){
          this.checkTransferEnable();
          this.loadClubInfo();
          this.getCurrentSeason();
        }
        this.getMembershipStatus();

        var self = this;
        $(document).on("click", ".deleteRoster", function(){
            self.deleteRoster($(this).data("club"));
        });
        $(document).on("change", ".bulkActChk", function(){
            self.selected.push($(this).val());
        });
        $(document).on("click", ".transferRoster", function(){
            self.showLoader=true;
            self.isUnderAgeDivision($(this).data("club_player_dob"));
            self.transferRoster($(this).data("club"));

        });
      $(document).on("click", "#selectall", function () {
        $(".bulkActChk").prop("checked", $(this).prop("checked"));
      });

      $(document).on("click", ".bulkActChk", function () {
        if (!$(this).prop("checked")) {
          $("#selectall").prop("checked", false);
        } else {
          var allChecked = true;
          $(".bulkActChk").each(function () {
            if (!$(this).prop("checked")) {
              allChecked = false;
              return false;
            }
          });
          $("#selectall").prop("checked", allChecked);
        }
      });
    },
    watch: {
        selected(newValue, oldValue) {
            // Handle changes in individual flavour checkboxes
            if (newValue.length === 0) {
                this.indeterminate = false
                this.allSelected = false
            } else if (newValue.length === this.rostersInfo.length) {
                this.indeterminate = false
                this.allSelected = true
            } else {
                this.indeterminate = true
                this.allSelected = false
            }
        }
    },
    computed: {
      club_member_delete_option: function(){
        return this.getSiteProps('clubMemberRoster.club_member_delete_option');
      },
        visibleFields() {
            return this.tblFields.filter(field => {
                if(this.subdomain == 'shooting'){
                    if(['sports_discipline', 'background_screening', 'official_rating'].indexOf(field.key) == -1) return field;
                }
                else if(this.subdomain == 'alliancefastpitch'){
                    if(['safesport','sports_discipline', 'background_screening', 'official_rating'].indexOf(field.key) == -1) return field;
                }
                 else {
                    return field;
                }
                if(field.key == 'sports_discipline' && this.subdomain == 'waterski') return field;
                if(field.key == 'background_screening' && this.subdomain != 'alliancefastpitch') return field;
                if(field.key == 'official_rating' && this.subdomain != 'alliancefastpitch') return field;
                if(field.key == 'safesport' && this.subdomain != 'alliancefastpitch') return field;
            });
        },
        membershipStatusSelect2(){
            var list = [];
            this.membershipStatus.forEach((item, index) => {
                if(index > 0 && item != '')
                    list.push({ id: index, text: item });
            });
            return list;
        }
    }
}
</script>
<style>
body.modal-open.cke_dialog_open  #modal-bulk-email___BV_modal_outer_ #modal-bulk-email{
  display: none !important;
}
</style>
